<template>
  <div class="common-problem">
    <img src="../assets/img/fb-wentibg.png" class="bg-img" alt="" />
    <!-- <div class="search-box">
			<el-input v-model="searchVal"></el-input>
			<span class="searchBtn" @click="search">搜索</span>
		</div> -->
    <div class="common-content">
      <div class="content-left">
        <div class="content-left-title">常见问题分类</div>
        <div class="left-menu" v-for="(item, index) in menuArr" :key="index">
          <div class="one-menu">{{ item.oneMenu }}</div>
          <div
            class="two-menu"
            :class="{ active: active.id == temp.id }"
            v-for="(temp, i) in item.twoMenu"
            :key="'twoMenu' + i"
            @click="openProblem(temp)"
          >
            {{ temp.title }}
          </div>
        </div>
      </div>
      <div class="content-right">
        <div class="content-right-hedaer">
          <img
            src="../assets/img/fb-wentizhuye.png"
            alt=""
            class="problem-img"
          />
          <span class="problem-text">常见问题</span>
          <span class="el-icon-arrow-right arrow-right" v-if="active.id"></span>
          <span class="problem-now" v-if="active.id">{{ active.title }}</span>
        </div>
        <div class="admin-content" v-html="active.content">
          <!-- {{}} -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "commonProblem",
  data() {
    return {
      // 常见问题分类数组
      menuArr: [
        {
          oneMenu: "下单说明",
          twoMenu: [
            {
              title: "如何邮寄包裹",
              id: 1,
              content:
                "1、包裹需要自行打包并称重<br/>您要发的快件包裹必须由您自行包装并称重，建议客户使用双层(Double Wall)的纸箱，并且在包裹内部做好足够的减震措施（如缠绕气泡膜；奶粉建议使用气柱袋等），防止包裹内的物品可能在长途运输的过程中损坏<br/>2、登陆GOCN自助下单<br/>包裹打包完毕并自行称重后，在GOCN官网输入包裹重量及尺寸信息，询价后选择相应的服务下单，可以根据物品内件来选择合适得服务，下单时可以选择将包裹自送到总部仓库；输入包裹信息后需要在线支付运费才能收到快递面单，最后一步就是将快递面单贴在箱子上送到相应的投递点或是等待司机上门取件即可。",
            },
            {
              title: "如何支付订单运费",
              id: 2,
              content:
                "GOCN（英国）支付货币统一为英镑，在线下单将包裹加入购物车之后可以统一付款。目前网站支持使用：在线银行卡（Visa / MasterCard）、在线信用卡（Visa / MasterCard）、支付宝、微信 进行支付订单运费，快速便捷。",
            },
            {
              title: "包裹上要贴什么文件",
              id: 3,
              content:
                "在下单成功后需要进入 ‘ 我的账户 - 订单详情 ’ 下载并打印面单，在保证打印出来的面单足够清晰明显之后将打印的面单文件贴牢在包裹面积最大的一面。<br/>一个订单对应一个包裹，请勿重复使用面单，否则所产生的丢件，超额费用等将由下单人自行承担。",
            },
            {
              title: "下单时收发件人信息填写错误",
              id: 4,
              content:
                "如果下单后发现填错了寄件人及收件人信息，当包裹还在您手边时，先不要着急寄出，先尽快联系客服进行尝试修改；若包裹已经寄出，也需要尽快联系客服，但客服只能尝试尽量帮您修改，部分服务不保证一定可以修改成功；所以在下单时一定要再三检查，以免因为地址错误造成包裹延误丢件等其他情况。",
            },
            {
              title: "如何取消订单",
              id: 5,
              content:
                "需要联系客服进行协商取消，且可能会有额外的手续费产生；由于系统都是实时链接运营商系统，出单后就产生了费用，所以建议付款之前仔细核对信息，避免造成不必要的手续费损失",
            },
            {
              title: "没有秤如何知道包裹重量",
              id: 6,
              content:
                "一般情况下，六罐奶粉打包后的重量连同纸箱大约在7KG内，四罐奶粉打包后的重量连同纸箱大约在5KG内，如长期邮寄包裹建议可以购买专用秤或手提行李秤或来测量包裹重量，体重秤无法精准测量包裹重量且容易出现的误差较大，需要避免使用体重秤来测量的情况以免后期出现补款。",
            },
            {
              title: "想寄的产品不在申报列表内",
              id: 7,
              content:
                "若您想要邮寄的物品不在申报列表内，您可以联系客服进行申报，待审核通过后，我们会将此物品加入到申报列表内，届时您就可以使用我们的路线邮寄您的物品。",
            },
          ],
        },
        {
          oneMenu: "清关和税金",
          twoMenu: [
            {
              title: "包裹邮寄中国会有税金吗",
              id: 8,
              content:
                "包清关的服务即表示包裹寄出之后中间所有的海关服务（包含税款缴纳）等均由GOCN负责，简单来说包裹寄出之后就是收件人等着收件，没有任何手续或文件需要收件人办理；可以通过首页的服务介绍页面查询哪些服务属于包清关服务。<br/>不包清关的服务在包裹寄出后，如果需要征税或退运，海关会通知国内收件人进行办理清关手续，一旦产生关税或者退运需要客户（收件人）缴纳办理；具体条款可参考地方海关条款。",
            },
            {
              title: "可以税金代缴吗",
              id: 9,
              content:
                "GOCN的所有自营路线，采用的是‘税金补贴’的方式，即最后支付的费用=运费+税金；下单之后所有海关的手续寄件人和收件人都无需进行任何处理，我们会代为做好所有的清关手续。",
            },
          ],
        },
      ],
      // 当前点击问题
      active: {},
      // 常见问题搜索值
      searchVal: "",
    };
  },
  methods: {
    // 点击问题显示解决方案
    openProblem(row) {
      this.active = row;
    },
    // 搜索
    search() {},
  },
};
</script>
<style lang="less" scoped>
.common-problem {
  background: #f4f4f4;
  padding-bottom: 20px;
  .bg-img {
    width: 100%;
  }
}
// .search-box{
// 	position: relative;
// 	z-index: 11;
// 	width: 578px;
// 	margin: 0 auto;
// 	top: -140px;
// 	.searchBtn{
// 		display: inline-block;
// 		background: #6D5EFE;
// 		position: absolute;
// 		right: 0;
// 		cursor: pointer;
// 		color: #FFFFFF;
// 		line-height: 40px;
// 		text-align: center;
// 		width: 108px;
// 		border-top-right-radius: 24px;
// 		border-bottom-right-radius: 24px;
// 	}
// }
.common-content {
  width: 1000px;
  height: 609px;
  background: #ffffff;
  border-radius: 8px;
  margin: 0 auto;
  margin-top: -100px;
  position: relative;
  z-index: 10;
  display: flex;
  .content-left {
    width: 224px;
    border-right: 1px solid #e5daeb;
    // 左侧标题
    .content-left-title {
      height: 68px;
      line-height: 68px;
      padding-left: 24px;
      font-weight: bold;
      font-size: 16px;
      color: #1e2b4f;
    }
    // 左侧菜单盒子
    .left-menu {
      padding-left: 24px;
      .one-menu {
        width: 176px;
        height: 36px;
        padding-left: 12px;
        background: #f3f4fd;
        border-radius: 8px;
        font-weight: 600;
        color: #1e2b4f;
        line-height: 36px;
        font-size: 14px;
        margin-bottom: 12px;
      }
      .two-menu {
        font-weight: 400;
        color: #1e2b4f;
        font-size: 14px;
        margin-bottom: 20px;
        padding-left: 12px;
        cursor: pointer;
        &:hover {
          color: #6d5efe;
          font-weight: bold;
        }
      }
      .active {
        color: #6d5efe;
        font-weight: bold;
      }
    }
  }
  // 右侧盒子
  .content-right {
    flex: 1;
    // 右侧顶部
    .content-right-hedaer {
      height: 68px;
      line-height: 68px;
      border-bottom: 1px solid #e5daeb;
      vertical-align: middle;
      .problem-img {
        width: 16px;
        margin: 0 6px 0 24px;
        vertical-align: middle;
      }
      .problem-text {
        font-size: 14px;
        font-weight: 400;
        color: #1e2b4f;
        vertical-align: middle;
      }
      .arrow-right {
        vertical-align: middle;
        color: #b1b6c3;
        font-weight: bold;
        margin: 0 20px;
      }
      .problem-now {
        vertical-align: middle;
        font-weight: 400;
        color: #6d5efe;
        font-size: 14px;
      }
    }
    .admin-content {
      padding: 24px;
      font-size: 14px;
      font-weight: 400;
      color: #1e2b4f;
      line-height: 30px;
    }
  }
}
</style>
